
<template>
  <div class="d-flex justify-space-between items-center">
    <div class="uploaded-image-preview">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
      ></v-progress-circular>
      <img v-else-if="imageSrc" :src="imageSrc" />
      <div v-else>
        Ingen bild
      </div>
    </div>
    <div class="flex-grow-1 ml-2">
      <v-file-input
        :label="fieldLabel"
        dense
        outlined
        truncate-length="15"
        background-color="white"
        @change="onChange()"
        v-model="image"
        :disabled="isLoading"
        :accept="accept"
      >
      </v-file-input>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import useCore from '../helpers/core';
import useUrl from '../helpers/url'

export default {
  name: 'imageUpload',
  props: {
    label: { type: String, required: true },
    fieldLabel: { type: String, required: true },
    current: { type: String, required: false },
    recordId: { type: Number, required: true },
  },
  components: {
  },
  setup(props) {
    const image = ref(null);
    const { callApi } = useCore();
    const { composeCachelessUrl } = useUrl();
    const imageSrc = ref(null);
    const isLoading = ref(true);
    const accept = ref('');

    const onChange = () => {
      if (image.value && image.value.name) {
        // upload image
        const formData = new FormData();
        formData.append('file', image.value);
        formData.append('label', props.label);
        formData.append('recordId', props.recordId);
        callApi({
          method: 'post',
          path: '/cms/files/image',
          formData,
        })
          .then((apiResponse) => {
            // reload preview
            imageSrc.value = composeCachelessUrl(apiResponse.fileSrc);
            image.value = null;
            isLoading.value = false;
          })
          .catch((err) => {
            isLoading.value = false;
            return err;
          });
      }
    }

    // lifecycle methods
    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: `/cms/files/image-upload-on-mount/${props.label}`,
      })
        .then((apiResponse) => {
          accept.value = apiResponse.accept;
          if (props.current) {
            // load preview
            imageSrc.value = composeCachelessUrl(`${apiResponse.url}/${props.current}`);
          }
          isLoading.value = false;
        })
        .catch((err) => {
          isLoading.value = false;
          return err;
        });
    };

    onMounted(doOnMounted);

    return {
      onChange,
      image,
      imageSrc,
      isLoading,
      accept,
    };
  },
};
</script>

<style>
  div.uploaded-image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;
    background-color: rgba(0,0,0,0.2);
  }
  div.uploaded-image-preview > div {
    font-size: 14px;
  }
  div.uploaded-image-preview > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
</style>