
<template>
  <div>
    <v-text-field
      :label="label"
      :placeholder="placeholder"
      :value="value"
      @change="onUpdateValue($event)"
      persistent-placeholder
      dense
      outlined
      background-color="white"
      append-icon="mdi-clock"
      @click:append="onToggleClock"
      :rules="rules"
    />
    <v-row justify="center">
      <v-dialog
        v-model="isOpen"
        max-width="290">
        <v-card>
          <v-time-picker
            format="24hr"
            full-width
            :value="value"
            @change="onUpdateValueAndTogglePicker($event)">
          </v-time-picker>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  name: 'dateInput',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: { type: String, required: false },
    placeholder: { type: String, required: false },
    value: { type: String, required: false },
    minTime: { type: String, required: false },
    maxTime: { type: String, required: false },
    rules: { type: Array, required: false },
  },
  components: {
  },
  setup(props, context) {
    const isOpen = ref(false);

    const onToggleClock = () => {
      isOpen.value = !isOpen.value;
    };

    const onUpdateValue = (value) => {
      context.emit('change', value);
    };

    const onUpdateValueAndTogglePicker = (value) => {
      onUpdateValue(value);
      onToggleClock();
    };

    return {
      isOpen,
      onToggleClock,
      onUpdateValue,
      onUpdateValueAndTogglePicker,
    };
  },
};
</script>

<style>

</style>