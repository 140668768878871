<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar
        :title="title"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="mt-4 responsive-container-class">
      <v-form ref="form" lazy-validation>
        <v-row class="pt-2">
          <v-col cols="12" sm="6">
            <v-text-field
              label="Namn"
              v-model="formValues.epr.epr_str_name"
              placeholder="Stenhårt rockband"
              persistent-placeholder
              dense
              outlined
              background-color="white"
              :rules="[rules.required]"
              >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Land"
              v-model="formValues.epr.epr_str_country"
              placeholder="Sverige"
              persistent-placeholder
              dense
              outlined
              background-color="white">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="pt-2">
          <v-col cols="12" sm="6">
            <v-select
              label="Preliminär speldag"
              placeholder="Välj..."
              :items="selectableDays"
              v-model="formValues.epr.epr_dat_preliminary_day"
              item-value="value"
              item-text="label"
              dense
              outlined
              persistent-placeholder
              background-color="white">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              label="Spelningsslot"
              placeholder="Välj..."
              :items="selectableEpcs"
              v-model="formValues.epcs"
              item-value="epc_id"
              item-text="_.label"
              dense
              outlined
              persistent-placeholder
              background-color="white"
              multiple>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <app-date-input
              label="Publiceringsdatum"
              v-model="_epr_dat_publish_DATE"
              :rules="[rules.date]" />
          </v-col>
          <v-col cols="12" sm="6">
            <app-time-input
              label="Publiceringstid"
              v-model="_epr_dat_publish_TIME"
              :rules="[rules.time]" />
          </v-col>
        </v-row>
        <v-row
          v-for="(epx, index) in formValues.epxs"
          :key="'epx' + index">
          <v-col cols="1">
            <v-img :src="'https://kobra.webomaten.se/graphic/flag/48/' + epx.lng_str_flag + '.png'" width="40" style="top:-5px" />
          </v-col>
          <v-col cols="11">
            <v-text-field
              label="Underrubrik"
              v-model="epx.epx_str_byline"
              persistent-placeholder
              dense
              outlined
              background-color="white"
            >
            </v-text-field>
            <v-textarea
              label="Presentation"
              v-model="epx.epx_txt_presentation"
              persistent-placeholder
              dense
              outlined
              background-color="white">
            </v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="!isCreate">
          <v-col cols="12" sm="6">
            <app-image-upload
              label="epr-profile"
              fieldLabel="Profilbild"
              :current="formValues.epr.epr_str_profile_image"
              :recordId="id"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <app-image-upload
              label="epr-wallpaper"
              fieldLabel="Wallpaper"
              :current="formValues.epr.epr_str_wallpaper_image"
              :recordId="id"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              type="button"
              class="mt-2"
              small
              depressed
              color="secondary"
              @click="onAddMetadata">
              <v-icon>mdi-plus</v-icon>
              Lägg till Metadata
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-for="(epm, index) in formValues.epms"
          :key="'epm' + index">
          <v-col cols="4">
            <v-select
              :items="mdts"
              item-text="mdt_str_name"
              item-value="mdt_id"
              label="Typ av metadata"
              v-model="epm.epm_id_mdt"
              dense
              outlined
              background-color="white"
              hide-details>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Värde"
              v-model="epm.epm_str_value"
              persistent-placeholder
              dense
              outlined
              background-color="white"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              v-if="!isCreate"
              outlined
              color="red"
              type="button"
              @click="onRemoveMetadata(index)"
              small>
              <v-icon left>mdi-trash-can-outline</v-icon>
              Ta bort
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-8">
            <v-btn
              v-if="!isCreate"
              text
              color="red"
              type="button"
              class="mt-4"
              @click="onDelete"
              small>
              <v-icon left>mdi-trash-can-outline</v-icon>
              Ta bort band
            </v-btn>
          </v-col>
        </v-row>      </v-form>
    </v-container>

    <app-confirm
      :show="showDeleteConfirm"
      title="Ta bort bandet?"
      @onYes="onDeleteConfirm"
      @onNo="showDeleteConfirm = false">
    </app-confirm>
  </div>
</template>

<script>

import { onMounted, reactive, ref } from '@vue/composition-api';
import { DateTime } from 'luxon';
import useCore from '../../helpers/core';
import useValidation from '../../helpers/validation'
import ViewToolbar from '../../components/ViewToolbar.vue';
import Confirm from '../../components/Confirm.vue';
import DateInput from '../../components/DateInput.vue';
import TimeInput from '../../components/TimeInput.vue';
import ImageUpload from '../../components/ImageUpload.vue';

export default {
  name: 'eventPerformerPut',
  props: {
    evnId: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: false,
    }
  },
  components: {
    'app-view-toolbar': ViewToolbar,
    'app-confirm': Confirm,
    'app-date-input': DateInput,
    'app-time-input': TimeInput,
    'app-image-upload': ImageUpload,
  },
  setup({ evnId, id }) {
    const router = require('@/router/router').default;

    const { callApi, showMessage } = useCore();
    const { rules } = useValidation();

    const isCreate = !id;
    const showDeleteConfirm = ref(false);
    
    const dataLoaded = ref(false);
    const title = ref('Band');
    const form = ref(null);
    const mdts = ref([]);
    const selectableEpcs = ref([]);
    const selectableDays = ref([]);

    // Partials
    const _epr_dat_publish_DATE = ref('');
    const _epr_dat_publish_TIME = ref('');

    // Data object
    const formValues = reactive({
      epr: {
        epr_str_wallpaper_image: null,
        epr_str_profile_image: null,
        epr_str_name: '',
        epr_dat_publish: null,
        epr_str_country: null,
        epr_dat_preliminary_day: null,
      },
      epxs: [],
      epms: [],
      epcs: [],
      attachment_profile_image: null,
      attachment_wallpaper_image: null,
    });

    const setEprDatPublish = () => {
      const date = DateTime.fromISO(_epr_dat_publish_DATE.value);
      const time = DateTime.fromISO(_epr_dat_publish_TIME.value);

      // If date is not valid neither time should be set; Set empty
      if (!date.isValid) {
        formValues.epr.epr_dat_publish = null;
        return;
      }

      // Date is set but time not valid; Set 00:00:0:000
      if (!time.isValid) {
        formValues.epr.epr_dat_publish = date.set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }).toISO();
        return;
      }

      // Both date and time is set
      formValues.epr.epr_dat_publish = date.set({
        hour: time.hour,
        minute: time.minute,
        second: 0,
        millisecond: 0,
      });
    };

    // Actions
    const onSave = () => {
      // Invalid? Exit
      const formIsValid = form.value.validate();

      if (!formIsValid) {
        showMessage('Formuläret är inte komplett ifyllt');
        return;
      }

      // Submit Create/Update
      const method = isCreate ? 'post' : 'put';
      const path = isCreate
        ? `/cms/events/${evnId}/performers`
        : `/cms/events/${evnId}/performers/${id}`;
      
      setEprDatPublish();
      formValues.epcs = formValues
        .epcs
        .map((e) => typeof e === 'number' ? e : e.epc_id);
      
      callApi({ method, path, data: formValues })
        .then((res) => {
          showMessage(`${res.epr.epr_str_name} har sparats`);
          router.replace({ name: 'eventDashboard', params: { evnId } });
        })
        .catch((err) => {
          return err;
        });
    };

    const onDelete = () => {
      showDeleteConfirm.value = true;
    }
    const onDeleteConfirm = () => {
      callApi({ method: 'delete', path: `/cms/events/${evnId}/performers/${id}` })
        .then(() => {
          showMessage('Band har tagits bort');
          router.replace({ name: 'eventDashboard', params: { evnId } });
        })
        .catch((err) => {
          return err;
        });
    };

    const onAddMetadata = () => {
      formValues.epms.push({ epm_id_mdt: null, epm_str_value: '' });
    };
    const onRemoveMetadata = (index) => {
      formValues.epms.splice(index, 1);
    };

    // Toolbar actions
    const toolbarButtons = [{ icon: 'check', name: 'Spara', fn: onSave }];
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: `/cms/events/${evnId}/performers/${id}`,
      })
        .then((res) => {
          dataLoaded.value = true;
          selectableEpcs.value = res._.selectableEpcs;
          selectableDays.value = res._.selectableDays;
          // No record found; Exit
          if (res.noRecord) { return; }
          title.value = `Redigera ${res.epr.epr_str_name}`;
          // Record found; Populate form
          formValues.epr.epr_str_name = res.epr.epr_str_name;
          formValues.epr.epr_str_country = res.epr.epr_str_country;
          formValues.epr.epr_str_profile_image = res.epr.epr_str_profile_image;
          formValues.epr.epr_str_wallpaper_image = res.epr.epr_str_wallpaper_image;
          formValues.epr.epr_dat_preliminary_day = res.epr.epr_dat_preliminary_day;
          formValues.epcs = res.epcs;
          formValues.epms = res.epms;

          // Parse and divide epr_dat_publish to sepearate date and time object
          const epr_dat_publish = DateTime.fromISO(res.epr.epr_dat_publish);
          if (epr_dat_publish.isValid) {
            _epr_dat_publish_DATE.value = epr_dat_publish.toISODate();
            _epr_dat_publish_TIME.value = epr_dat_publish.toISOTime({
              includePrefix: false,
              extendedZone: false,
              includeOffset: false,
              suppressSeconds: true,
              suppressMilliseconds: true,
            });
          }
          formValues.epxs = res.epxs;
          mdts.value = res._.mdts;
        })
        .catch((err) => {
          router.replace({ name: 'eventDashboard', params: { evnId } });
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      showDeleteConfirm,
      onDeleteConfirm,

      dataLoaded,
      form,
      rules,
      title,
      mdts,
      selectableEpcs,
      selectableDays,
      _epr_dat_publish_DATE,
      _epr_dat_publish_TIME,
      isCreate,
      formValues,
      toolbarButtons,
      onToolbarButton,
      onDelete,
      onAddMetadata,
      onRemoveMetadata,
    };
  },
};
</script>

<style>
</style>